const uiColors = {
  overlay: {
    default: 'rgba(45, 3, 52, 0.9)',
  },
  primary: {
    default: '#810CC999',
    hover: '#A209E2',
    pressed: '#4C0077',
    disabled: '#9F72B999',
  },
  secondary: {
    default: '#E209C099',
    hover: '#FF41E1',
    pressed: '#9A0587',
  },
  toggled: {
    default: '#F00073',
  },
  cancel: {
    default: '#F11C5C',
    hover: '#FF2C6B',
    pressed: '#E00043',
  },
  panel: {
    default: '#6C026F4D',
  }
};


const uiConfig = {
  H: {
    colors: uiColors,
    offsets: {
      right_panel: { top: 0, left: -11 },
    },
  },
  V: {
    colors: uiColors,
    offsets: {
      top: -30,
      left: 0,
      tournaments_panel: {
        top: -80,
        left: 10,
      }
    },
  }
};

export {
  uiConfig,
}
