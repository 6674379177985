import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import SlotGameModel from 'Engine/slots/model/SlotGameModel';
import ServerManager from '../server/ServerManager';

export default new class GameModel extends SlotGameModel {
  constructor() {
    super();
    this._freespins = 0;
    this._freespinsTotalWin = 0;
    this._freespinsGameTriggered = false;
  }

  init () {
    super.init();
    ServerManager.onInit();
  }

  setNewData(data) {
    super.setNewData(data);
    if (data.jackpotGame) {
      this._winJackpotType = data.jackpotGame.winJackpotType;
      this._jackpotWinAmount = data.jackpotGame.jackpotWinAmount;
      if (this._jackpotWinAmount) window.OPWrapperService.ControllerStatistic.win = this._jackpotWinAmount;
      this._jackpotField = data.jackpotGame.field || [[-1,-1,-1],[-1,-1,-1],[-1,-1,-1],[-1,-1,-1],[-1,-1,-1]];
      this._jackpotResults = data.jackpotGame.results || [0,0,0,0,0];
      if (data.jackpotGame.bet && !this._freebetsIsActive) this.bet = data.jackpotGame.bet;
    }

    if (data.freespinsData) {
      this._freespinsTotalWin = data.freespinsData.totalWin || 0;
      this._freespins = data.freespinsData.freespins;
      this._freespinsGameTriggered = data.freespinsData.freespinsGameTriggered;
    }
    this._isFreeSpinsMode = data.freespinsData && data.freespinsData.isFreespinsGame;

    this._progresesToJackpot = data.progresesToJackpot || this._progresesToJackpot;

    this._jackpotCoeffs = data.jackpotCoeffs || this._jackpotCoeffs;
    this._isJackpotGame = data.jackpotGame && data.jackpotGame.isJackpotGame;

    GlobalDispatcher.dispatch('model:getServerData', data.action);
  }

  get isBigWin() {
    return this.totalWin >= this.bet * 15;
  }

  get paytableCurrentInfo() {
    this._paytableCurrentInfo = {};
    for (const index in this._paytableInfo) {
      this._paytableCurrentInfo[index] = this._paytableInfo[index].map(coef => {
        return coef * this.bet;
      })
    }
    this._paytableCurrentInfo['wildField'] = this._paytableInfo[1][2] * this.lines

    return this._paytableCurrentInfo
  }
  get isJackpotGame() {
    return this._isJackpotGame;
  }
  get jackpotField() {
    return this._jackpotField;
  }
  get jackpotResults() {
    return this._jackpotResults;
  }
  get winJackpotType() {
    return this._winJackpotType;
  }
  get jackpotWinAmount() {
    return this._jackpotWinAmount ?? 0;
  }
  get jackpotList() {
    return this._jackpotCoeffs.map(coef => this.bet * coef);
  }
  get freespins() {
    return this._freespins;
  }
  set freespins(value) {
    this._freespins = value;
  }
  get freespinsTotalWin() {
    return this._freespinsTotalWin;
  }
  set freespinsTotalWin(value) {
    this._freespinsTotalWin = value;
  }
  get freespinsGameEnabled() {
    return this._freespinsGameEnabled;
  }
  set freespinsGameEnabled(value) {
    this._freespinsGameEnabled = value;
  }
  get freespinsEnabled() {
    return this._freespinsGameEnabled;
  }
  get freespinsGameTriggered() {
    return this._freespinsGameTriggered;
  }
  get progressToJackpot() {
    return this._progresesToJackpot[this._betIndex].progress;
  }
  get totalWin() {
    let win = 0
    if (this._winnings) {
      win += this.winnings.totalWin;
      if (this.isJackpotGame) {
        win -= this.jackpotWinAmount
      }
    }
    return win;
  }
}
