import EntryPoint from 'Engine/EntryPoint';

export default new class XXXDataParser {
    parseInit(data) {
        let newData = {};
        newData.action = 'init';
        newData.balance = data.balance;
        newData.params = {
            linesPossible: [10],
            betPossible: data.bets_available
        };
        newData.current = {
            bet: data.bet_amount,
            lines: 5
        };
        if (data.isJackpot) {
            newData.jackpotGame = {
                isJackpotGame: data.isJackpot,
                field: data.jackpot.field,
                results: data.jackpot.progress
            }
        }
        newData.platformData = {
            historyUrl: data.history_url,
            lobbyUrl: data.lobby_url
        };

        newData.currencyInfo = {
            decimals: data.decimals,
            symbol: data.currency,
            currency: data.currency
        };

        newData.paytableInfo = data.slot_machine.paytable;

        newData.jackpotCoeffs = data.jackpots_coeffs;

        newData.freespinsData = this.makeFreeSpins(data);

        newData.progresesToJackpot = data.jack_potter_progress;

        newData.freebets = data.freebets

        return newData;
    }

    parseSpin(data) {
        let newData = {};
        newData.action = 'spin';
        newData.balance = data.balance;
        newData.spin = {
            reels: data.spin.reels,
            winnings: this.makeWinnings(data),
            bet: data.bet_amount,
        };

        newData.jackpotGame = {
            isJackpotGame: data.isJackpot,
        }

        newData.jackpotCoeffs = data.jackpots_coeffs;

        newData.freespinsData = this.makeFreeSpins(data);

        newData.progresesToJackpot = data.jack_potter_progress;

        newData.freebets = data.freebets

        return newData;
    }

    parseJackpotPlaceOpen(data) {
        let newData = {};

        newData.action = 'jackpot_step';
        newData.balance = data.balance;

        newData.jackpotGame = {
            isJackpotGame: !(typeof(data.winJackpotType) === 'number' && data.winJackpotType >= 0),
            isJackpotWin: typeof(data.winJackpotType) === 'number' && data.winJackpotType >= 0,
            winJackpotType: data.winJackpotType,
            jackpotWinAmount: data.winAmount,
            bet: data.bet_amount,
        }

        newData.freespinsData = this.makeFreeSpins(data);

        newData.freebets = data.freebets

        return newData;
    }

    makeWinnings( data) {
        const spinData = data.spin;
        let winnings = {};
        winnings.totalWin = spinData.total_win || 0;

            winnings.lines = [];
        for (const lineNumber in spinData.win_lines) {
            const lineLeng = spinData.lines_icons[lineNumber];
            if(lineLeng < 3) continue;
            let line = {};
            line.path = EntryPoint.configData.payLines[lineNumber - 1].concat().splice(0, lineLeng);
            // line.line = this.linesRelation[winData.payline.number];
            line.line = lineNumber - 1;
            line.count = lineLeng;
            line.win = spinData.win_lines[lineNumber] * data.bet_amount / 20;

            winnings.lines.push(line);
        }

        if (data.jackpotter_positions?.length) {
            winnings.jackpotters = [];
            let jackpotter = {};
            jackpotter.symbol = 1;
            const pathArr = new Array(3);
            data.jackpotter_positions.forEach(reelData => {
                pathArr[reelData[0]] = reelData[1]+1;
            })
            jackpotter.path = pathArr;
            jackpotter.count = data.jackpotter_positions.length;
            // scatter.win = data.scatters_win;

            winnings.jackpotters.push(jackpotter);

            // winnings.totalWin += scatter.win;
        }


        return winnings;
    }

    makeFreeSpins(data) {
        if (!data.freespins) {
            return null;
        }

        let freespinsData = {
            totalWin: data.freespins.freespins_total_win,
            freespins: data.freespins.freespins_count,
            freespinsGameTriggered: data.freespins.freespins_triggered,
            isFreespinsGame: data.freespins.is_freespins,
        };
        return freespinsData;
    }
}
